import React from 'react';

import Layout from '../components/furniture/Layout/Layout';
import Login from '../components/auth/Login/Login';

import generateTitle from '../utils/generateTitle';

const LogInPage = ({ location }) => {
  const pageTitle = generateTitle('Sign In');

  return (
    <Layout location={location} pageTitle={pageTitle}>
      <h1>{pageTitle}</h1>
      <Login />
    </Layout>
  );
};

export default LogInPage;
